import { Features } from '@danone-global/internal/react/core'

export const features: Features = {
  showGiftLineItems: true,
  enableNotifyMe: true,
  showRelatedProducts: true,
  enablePhoneVerification: true,
  showCheckboxesDuringAccountCreation: true,
  enableUpdateExternalProfile: true,
  hideReorderBtn: true,
  siteHK: true,
}
